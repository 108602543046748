<template>
  <div>
    <v-app-bar
      id="home-app-bar"
      app
      elevation="1"
      elevate-on-scroll
      height="80"
    >
      <!--logo-->
      <!--<base-img
        :src="require('@/assets/logo.svg')"
        class="mr-3 hidden-xs-only"
        contain
        max-width="46"
        width="100%"        :src="require('@/assets/compac/logo.png')"
      />-->
      <base-img
        :src="require('@/assets/compac/logo.png')"
        contain
        max-width="76"
        width="100%"
      />
      <h1
        style="color: red" align="center"
      >
        <!--<b>Consejo Mexicano de Patología Clínica </b> <br> <b>y Medicina de Laboratorio A.C.</b>-->
        COMPAC
      </h1>

      <!--<h2
        class="mr-3 hidden-md-and-up"
        style="color: red" align="center">
        <b>COMPAC</b>
      </h2>-->
      <!-- as letras de zerotheme -->
     <!-- <base-img
        :src="require(`@/assets/zero-logo-${$vuetify.theme.isDark ? 'dark' : 'light'}.svg`)"
        contain
        max-width="96"
        width="100%"
      />-->

      <!--<base-img
        :src="require(`@/assets/zero-logo-${$vuetify.theme.isDark ? 'dark' : 'light'}.svg`)"
        contain
        max-width="96"
        width="100%"
      />-->
      <v-spacer />
      <div>
        <v-tabs
          class="hidden-sm-and-down"
          optional
          background-color="transparent"
        >

          <v-tab
            v-for="(name, i) in items"
            :key="i"
            :to="{ name }"
            :exact="name === 'Inicio'"
            :ripple="false"
            class="font-weight-bold"
            min-width="96"
            text
          >
            {{ name }}
          </v-tab>
        </v-tabs>
      </div>

      <!--<v-btn

        :ripple="true"
        v-ripple="{ class: 'blue--text' }"
        background-color="transparent"
        text
        class="font-weight-bold"
        style="color: #757575"
        to="consejo"

      >Estatuto
      </v-btn>-->

      <!--<div>
        <v-menu offset-y
                rounded="b-xl"
                v-model="menu"
                :nudge-width="100"
                :nudge-bottom="20"
                min-width="96"
                text
                class="hidden-sm-and-down v-tab"
                background-color="transparent"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              class="font-weight-bold"
              :ripple="true"
              background-color="transparent"
              text


            >El Consejo
            </v-btn>
          </template>
          <v-list>
            <v-list-item
              v-for="(item, index) in items_menu"
              :key="'consejo_path'+index"
              link
              :to="item.ruta"
            >
              <v-list-item-title

              >
                {{ item.title }}
              </v-list-item-title>
            </v-list-item>
          </v-list>

        </v-menu>
      </div>-->

      <v-app-bar-nav-icon
        class="hidden-md-and-up"
        @click="drawer = !drawer"
      />
    </v-app-bar>

    <home-drawer
      v-model="drawer"
      :items="items"
    />
  </div>
</template>
<script>
  export default {
    name: 'HomeAppBar',
    components: {
      HomeDrawer: () => import('./Drawer'),
    },
    data: () => ({
      drawer: null,
      fav: true,
      menu: false,
      message: false,
      hints: true,
      items_menu:[
        { title: 'Medicos', ruta:'medicos' },
        { title: 'Consejo',ruta:'consejo' },
        { title: 'Click Me3',ruta:'certificacion' },
        { title: 'Marketing',ruta:'marketing' },
      ],
      items: [
        'Inicio',
        'Consejo',
        'Certificacion',
        'Recertificacion',
        'Medicos',
        'Educacion',
        'Residencias',

        //'About',
        //'Marketing',
        //'Gallery',
        //'Pricing',
        //'News',
        //'Contact',
      ],
    }),
  }
</script>
<style>
  .v-btn::before {
    background-color: #42A5F5;
  }
  .v-btn.span::before {
    color: palevioletred;
  }



</style>
<style lang="sass">



  #home-app-bar
    .v-tabs-slider
      max-width: 24px
      margin: 0 auto

    .v-tab
      &::before
        display: none

    .v-menu
      &::before
        display: none



</style>
